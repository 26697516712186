import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner, { BannerBackgroundColor, BannerVariant } from 'components/Banner';
import RelatedProducts, { RELATED_PRODUCTS_VARIANTS } from 'containers/RelatedProducts';

const { PRODUCTS_LIST } = BannerVariant;
const { PURPLE } = BannerBackgroundColor;
const { LISTING } = RELATED_PRODUCTS_VARIANTS;

const ProductsListingPage: FC<ProductsListingPageTypes.ProductsListingProps> = ({
  data: {
    productsListing: { urls, seo, heroBanner, productCategories },
    allProductDetail: { nodes: relatedProductsNodes },
  },
}) => {
  const { title, keywords, description } = seo;

  const renderProducts = productCategories.map(({ productCategoryName, ctaButtonText }) => {
    const productsArray = relatedProductsNodes.filter(
      ({ productTitle }) => productTitle.toUpperCase() === productCategoryName.toUpperCase()
    );

    return (
      <RelatedProducts
        title={productCategoryName}
        ctaText={ctaButtonText}
        products={productsArray}
        variant={LISTING}
      />
    );
  });

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner variant={PRODUCTS_LIST} backgroundColor={PURPLE} {...heroBanner} />
      {renderProducts}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    productsListing(lang: { eq: $lang }) {
      ...ProductsListingPageFragment
    }
    allProductDetail {
      nodes {
        ...ProductDetailPageFragment
      }
    }
  }
`;

export default ProductsListingPage;
